import React from 'react'
import { Link } from 'react-router-dom'

import ContentCard from "../../ContentCard/ContentCard"

import styles from './styles.module.css'

const AboutCard = () => {
  return (
    <ContentCard title="ABOUT US">
      <p>
        Created by two friends, a one stop establishment equipped with charging stations and a trip planning assistance with all EV solutions.
      </p><br />
      <p>
        With the vision of sweeping widespread acclimatisation of the application across the country, ElectricHiive inspires to promote consumption of carbon emission-free transportation for a better future.
      </p><br />
      <p>
        Mission is to generate a user experience by simplifying the charging process with an end-to-end unified network all at a single tap and with additional services like Troubleshooters (local mechanics), a Smart trip planner, and roadside assistance.
      </p><br />
      <div className={styles.read_more_container}>
        <Link to="/aboutus" className={styles.readmore}>Read more</Link>
      </div>
    </ContentCard>
  )
}

export default AboutCard
