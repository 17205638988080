import React from 'react'
import styles from './styles.module.css'
// import { motion, useScroll, useTransform } from "framer-motion"

export default function ContentCard(props) {
  // const { scrollY } = useScroll()
  // const cardTransform = useTransform(scrollY, latest => latest*1)
  return (
    <div
      className={styles.content_card}>
      <div className={styles.card_container}>
        <div>
          <p className={styles.title}>{props.title}</p>
        </div>
        <div className={styles.content_container}>
          {props.children}
        </div>
      </div>
    </div>
  )
}