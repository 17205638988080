import React from 'react'

import styles from './styles.module.css'
import {useState} from 'react'

import NavModal from '../NavModal/NavModal';
import Footer from '../Footer/Footer';

const LinkedPageTemplate = (props) => {

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false)
  }

  return (
    <div className={styles.linked_page_template}>
      {showModal ? <NavModal handleModalClose={handleModalClose} /> : null}
      <div className={styles.linked_page_nav}>
        <button onClick={() => {setShowModal(true)}} className={styles.menu_btn}><img src='/menu_dots_green.png' alt="" className={styles.open_modal_dots} /></button>
        <img src='/logo_green.png' alt="" className={styles.ehiive_logo}/>
      </div>
      <img src={props.img_src} alt="" className={styles.banner_img}/>
      <div className={styles.page_container}>
        <div>
          <p className={styles.linked_template_heading}>{props.heading}</p>
        </div>
        <div className={styles.content_wrapper}>
          {props.children}
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default LinkedPageTemplate
