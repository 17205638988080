import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// import {HomePage, AboutPage, AllinonePage, ContactUsPage, CookiesPolicyPage, PrivacyPolicyPage, SmartTripsPage, TandCPage, TroubleshootersPage} from './pages/index'
import HomePage from './pages/HomePage/HomePage'
import AboutPage from './pages/AboutPage/AboutPage'
import AllinOnePage from './pages/AllinOnePage/AllinOnePage'
import ContactUsPage from './pages/ContactUsPage/ContactUsPage'
import CookiesPolicyPage from './pages/CookiesPolicyPage/CookiesPolicyPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage'
import SmartTripsPage from './pages/SmartTripsPage/SmartTripsPage'
import TandCPage from './pages/TandCPage/TandCPage'
import TroubleshootersPage from './pages/TroubleshootersPage/TroubleshootersPage'


const App = () => {
  const router = createBrowserRouter([
    {path:"/", element:<HomePage/>},
    {path:"/aboutus", element:<AboutPage/>},
    {path:"/allinone", element:<AllinOnePage/>},
    {path:"/contactus", element:<ContactUsPage/>},
    {path:"/cookies-policy", element:<CookiesPolicyPage/>},
    {path:"/privacy-policy", element:<PrivacyPolicyPage/>},
    {path:"/smarttrip", element:<SmartTripsPage/>},
    {path:"/terms-and-conditions", element:<TandCPage/>},
    {path:"/troubleshooters", element:<TroubleshootersPage/>},
  ])
  return (
    <div>
      <RouterProvider router={router}/>
    </div>
  )
}

export default App
