import React from 'react'

import ContentCard from "../../ContentCard/ContentCard"
import styles from './styles.module.css'

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { RiArrowDropDownLine } from "react-icons/ri";

const FaqCard = () => {

  const faq_array = [
    { question: "Q. Does ElectricHiive connect to my Electric Vehicle?", answer: "Ans. No, it doesn't connect to your EV." },
    { question: "Q. How do I download the ElectricHiive application?", answer: `Ans. Our application is available for download on both iOS and Android devices. Simply visit the App Store or Google Play Store and search for "ElectricHiive" to download and install the app.` },
    { question: "Q. Can I view real-time availability and status of charging stations through the application?", answer: `Ans. No, as of now you can view all the charging stations near you and enroute your trip.` },
    { question: "Q. How do I report issues or provide feedback about charging stations through the application?", answer: `Ans. We have a feedback mechanism for users to report issues, provide feedback, or submit inquiries regarding charging stations. You can also shoot a mail on 'contact@electrichiive.com' for the same.` },
    { question: "Q. Can I book a local mechanic for my EV maintenance and repairs?", answer: `Ans. Yes, you can book an appointment with the nearest local EV mechanic for the maintenance of your electric vehicle.` },
    { question: "Q. Can I plan a long route trip with ElectricHiive?", answer: `Ans. Yes, with our Smart trip, not only can you plan a long trip with your EV, ElectricHiive will also give your appropriate information regarding all the available charging stations in your route.` },
    { question: "Q. Can I call for assistance through the app, if my vehicle broke down?", answer: `Ans. Yes, with our local mechanic feature you can call for tow service, roadside assist and nearest mechanic to your location.` },
    { question: "Q. I do not have an electric vehicle, can I use the local mechanic facility?", answer: `Ans. Absolutely, except our charging feature which you still can access, you can freely use our local mechanic service, doesn't matter if you have a petrol or diesel car or a 2 wheeler vehicle.` },
    { question: "Q. What does E-hiive do differently that I should use their services?", answer: `Ans. We resolve your electric vehicle charging issues, including we are set to resolve any other mechanical issues for your every electric and non-electric issues, like maintenance, services, roadside assist and what not from service to vehicle breakdown.` },
    { question: "Q. I already have Ola and other apps on my phone. Why should I have another one?", answer: `Ans. Because after this app you won’t need any other app, after collaborating with the respective companies we will provide you access for their chargers through our app, and more, after that uninstall every other app, we will fix you up for everything.` },
    { question: "Q. Rewards and benefit types customer gets from Electrichiive?", answer: `Ans.` }
  ]

  const faq_accordions = faq_array.map((item) => {
    return (
      <Accordion key={item.question} className={styles.accordion}>
        <AccordionSummary expandIcon={<RiArrowDropDownLine />}>
          {item.question}
        </AccordionSummary>
        <AccordionDetails>
          {item.answer}
        </AccordionDetails>
      </Accordion>
    )
  })

  return (
    <ContentCard title="FAQ">
      <div className={styles.faq_card}>
        {faq_accordions}
      </div>
    </ContentCard>
  )
}

export default FaqCard
