import React from 'react'
import styles from "./styles.module.css";
const SmartTripCardLeft = ({img,title,description}) => {
  return (
    <div className={styles.section}>
    <p className={styles.heading_mobile_v}>
      <b>{title}</b>
    </p>
    <img
      src={img}
      alt=""
      className={styles.st_illustration}
    />
    <div className={styles.txt_wrapper}>
      <p className={styles.txt_heading}>
        <b>{title}</b>
      </p>
      <p className={styles.txt_para}>
        {description}
      </p>
    </div>
  </div>
  )
}

export default SmartTripCardLeft