import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles.module.css'

import SocialIcons from '../../components/SocialIcons/SocialIcons'

import { FaHeart } from "react-icons/fa"
export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_container}>
        <div className={styles.footer_container1}>
          <img src="/logo_white.png" alt="logo_footer" className={styles.logo_footer} />
          <p>Plug into possibilities</p>
          <SocialIcons />
          <p className={styles.dialogue}>CHARGED WITH <span className={styles.heart_icon}> <FaHeart fill='red' /></span> IN <img src="/india_flag_icon.png" alt="" className={styles.india_flag_icon} /></p>
        </div>

        <div className={styles.footer_container2}>
          <Link to="/">Home</Link>
          <Link to="/aboutus">About us</Link>
          <a href="/">FAQ</a>
          <Link to="/contactus">Contact us</Link>
        </div>

        <div className={styles.footer_container3}>
          <Link to="/smarttrip">Smart trips</Link>
          <Link to="/allinone">All in one Charging station</Link>
          <Link to="/troubleshooters">Troubleshooters</Link>
        </div>

        <div className={styles.footer_container4}>
          Stay upto date
          <div className={styles.input_container}>
            <input type="email" className={styles.input_field} placeholder="Email" required />
            <button className={styles.sub_btn}>
              Subscribe
            </button>
          </div>
          {/* <div className={styles.footer_store_btn_container}>
            <a href="/" ><img src="/app_store_btn.png" alt="" className={styles.footer_store_btn} /></a>
            <a href="/" className={styles.footer_store_btn}><img src="/google_play_btn.png" alt="" className={styles.footer_store_btn} /></a>
          </div> */}
          <div className={styles.tnc_container}>
            <Link to="/terms-and-conditions" target='blank'>Terms & conditions</Link>
            <Link to="/privacy-policy" target='blank'>Privacy Policy</Link>
            <Link to="/cookies-policy" target='blank'>Cookies policy</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}