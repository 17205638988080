import React from 'react'

import ContentCard from "../../ContentCard/ContentCard"

import styles from './styles.module.css'

const HomeCard = () => {
  return (
    <ContentCard title="HOME">
      <div className={styles.homecard_txt}>
        <p>
          Welcome to ElectricHiive, an all-stop platform to find and access all charging stations, and trip planner assistance with all EV solutions! Gone are the days of range anxiety, let's welcome hassle free journeys with our application.
        </p>
        <p>
          We're your all-stop platform for locating and accessing every charging station near you, coupled with expert trip planning assistance and troubleshooters (local mechanics) tailored specifically to your electric vehicle needs.
        </p>
        <p>
          Our unified network provides access to all charging providers in a single app and easy access to TROUBLESHOOTERS (local mechanics). Our intuitive trip planner takes the hassle out of route mapping, providing you with the optimal journey based on your EV's current charge percentage.
        </p>
      </div>

      <div className={styles.home_btn_container}>
        <a href="/"><img src="/app_store_btn.png" alt="" className={styles.home_store_btn} /></a>
        <a href="/"><img src="/google_play_btn.png" alt="" className={styles.home_store_btn} /></a>
      </div>
    </ContentCard>
  )
}

export default HomeCard
