import React, { useEffect } from "react";

import LinkedPageTemplate from "../../components/LinkedPageTemplate/LinkedPageTemplate";
import SmartTripCardLeft from "../../components/SmartTripCardLeft/SmartTripCardLeft";

import styles from "./styles.module.css";
import SmartTripCardRight from "../../components/SmartTripCardRight/SmartTripCardRight";

const SmartTripsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LinkedPageTemplate
      heading="SMART TRIP"
      img_src="/smarttrip_banner.png"
      btn_img="/menu_dots_white.png"
      logo_img="/logo_white.png">
      <p>
        Smart Trip is a complete service that is designed to assist EV owners in
        planning a hassle-free trip considering their vehicle charge status.
        You, as an EV user, have to feed in simple information about the start
        location, destination and the EV battery percentage, and the smart trip
        will automatically tell you about the optimal routes. The user can add
        additional stops in your trip and it will optimise the route
        accordingly.
      </p>
      <br />
      <p>
        Smart Trip offers the following range of features aimed at optimising
        routes and ensuring a seamless travel experience.
      </p>
      <SmartTripCardLeft
        img={"/tripplanning2.png"}
        title={"Trip planning and additional stops"}
        description={`The user can plan a hassle-free trip just by providing some basic
        information about start location, destination and EV battery
        percentage. According to the user's wish and convenience, the user
        can add additional stops to their trips, such as sightseeing spots,
        rest areas, etc. Smart Trip will accordingly plan the trip, ensuring
        minimal de-routing from the original plan while accommodating the
        additional stops.`}
      />

      <SmartTripCardRight img={"/route_calculation.png"}
        title={"Route calculation"}
        description={`Using proper algorithms, the smart trip will calculate the optimal
            route based on factors including the vehicle's charge status,
            distance, traffic conditions on the way, and the availability of
            charging stations along the way.`} />


<SmartTripCardLeft
        img={"/charge_management.png"}
        title={"Charge management"}
        description={`This feature considers the current charge percentage of the EV and
            accordingly plans the charging stations along the route to ensure
            that the vehicle has sufficient charge throughout the journey.`}
      />
      {/* <div className={styles.section1}>
        <p className={styles.heading_mobile_v}>
          <b>Trip planning and additional stops</b>
        </p>
        <img
          src="/tripplanning2.png"
          alt=""
          className={styles.st_illustration}
        />
        <div className={styles.txt_wrapper}>
          <p className={styles.txt_heading}>
            <b>Trip planning and additional stops</b>
          </p>
          <p className={styles.txt_para}>
            The user can plan a hassle-free trip just by providing some basic
            information about start location, destination and EV battery
            percentage. According to the user's wish and convenience, the user
            can add additional stops to their trips, such as sightseeing spots,
            rest areas, etc. Smart Trip will accordingly plan the trip, ensuring
            minimal de-routing from the original plan while accommodating the
            additional stops.
          </p>
        </div>
      </div>

      <div className={styles.section2}>
        <p className={styles.heading_mobile_v}>
          <b>Route calculation</b>
        </p>
        <img
          src="/route_calculation.png"
          alt=""
          className={styles.st_illustration}
        />
        <div className={styles.txt_wrapper}>
          <p className={styles.txt_heading}>
            <b>Route calculation</b>
          </p>
          <p className={styles.txt_para}>
            Using proper algorithms, the smart trip will calculate the optimal
            route based on factors including the vehicle's charge status,
            distance, traffic conditions on the way, and the availability of
            charging stations along the way.
          </p>
        </div>
      </div>

      <div className={styles.section3}>
        <p className={styles.heading_mobile_v}>
          <b>Charge management</b>
        </p>
        <img
          src="/charge_management.png"
          alt=""
          className={styles.st_illustration}
        />
        <div className={styles.txt_wrapper}>
          <p className={styles.txt_heading}>
            <b>Charge management</b>
          </p>
          <p className={styles.txt_para}>
            This feature considers the current charge percentage of the EV and
            accordingly plans the charging stations along the route to ensure
            that the vehicle has sufficient charge throughout the journey.
          </p>
        </div>
      </div> */}
    </LinkedPageTemplate>
  );
};

export default SmartTripsPage;
