import React from 'react'
import ContentCard from "../../ContentCard/ContentCard"

import styles from './styles.module.css'

const ServicesCard = () => {
  return (
    <ContentCard title="SERVICES">
      <div className={styles.services_card}>
        <b><p>Charging stations</p></b>
        <p>No more hassle of multiple applications, just one tap will connect you to a unified network providing access to all charging providers.</p><br />

        <b><p>Troubleshooters (Local Mechanics)</p></b>
        <p>Local mechanic services incorporated to provide service maintenance in the middle of the road or half-way through a city.</p><br />

        <b><p>Smart Trips</p></b>
        <p>Plan your trip and route according to your vehicle charge, put your start location and ending destination along with vehicle charge percentage and it will help you know the optimal route. You can add additional stoppage in your trip and it will optimise the route accordingly.</p>
      </div>

    </ContentCard>
  )
}

export default ServicesCard
