import React from 'react'

import styles from './styles.module.css'

import SocialIcons from '../../SocialIcons/SocialIcons'
import ContentCard from '../../ContentCard/ContentCard'

const ContactUsCard = () => {
  return (
    <ContentCard title="CONTACT US">
      <div className={styles.contactus_page_container}>
        <p>Hi there!!
          Please contact us with your queries and feedbacks
          we also won't mind a compliment too<br/>
          Heart and peace to you!! <br/>
          Team <span className={styles.ehiive_emphasis}>Electrichiive</span>.</p>
        <div>
          <b><p>E-mail id</p></b>
          <p>contact@electrichiive.com</p>
        </div>
        {/* <div>
          <b><p>Contact no.</p></b>
          <p>+91-999000888</p>
        </div> */}
        <div>
          <b><p>Based in</p></b>
          <p>Jaipur, Rajasthan</p>
        </div>
        <div>
          <b><p>Socials</p></b>
          <SocialIcons/>
        </div>
      </div>
    </ContentCard>
  )
}

export default ContactUsCard
