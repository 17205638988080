import React from 'react'
import { FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import styles from './styles.module.css'

const SocialIcons = () => {
  return (
    <div className={styles.icon_container}>
      <a href='https://www.instagram.com/electrichiive/?igsh=MXJ3YmY1bzduaW9vNQ%3D%3D' target='blank' className={styles.icon_wrapper}><FaInstagram /></a>
      <a href='https://www.facebook.com/profile.php?id=61555940112523' target='blank' className={styles.icon_wrapper}><FaFacebookF /></a>
      <a href='' target='blank' className={styles.icon_wrapper}><FaXTwitter /></a>
      <a href='https://www.linkedin.com/company/99372546/admin/feed/posts/' className={styles.icon_wrapper} target='blank'><FaLinkedinIn /></a>
    </div>
  )
}

export default SocialIcons


