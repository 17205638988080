import React, { useEffect } from "react";

import LinkedPageTemplate from "../../components/LinkedPageTemplate/LinkedPageTemplate";
import SocialIcons from "../../components/SocialIcons/SocialIcons";

import styles from "./styles.module.css";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LinkedPageTemplate
      heading=" CONTACT US"
      img_src="/contactus_banner.png"
      btn_img="/menu_dots_white.png"
      logo_img="/logo_white.png">
      <p className={styles.contact_txt}>
        Hi there!! Please contact us with your queries and feedbacks we also
        won't mind a compliment too
        <br />
        Heart and peace to you!!{" "}
      </p>
      <p className={styles.contact_txt}>
        Team <span className={styles.ehiive_emphasis}> Electrichiive </span>.
      </p>
      <div className={styles.contact_container}>
        <div className={styles.contact_left}>
          <div>
            <b>
              <p>E-mail id</p>
            </b>
            <p>contact@electrichiive.com</p>
          </div>
          {/* <div>
            <b><p>Contact no.</p></b>
            <p>+91-999000888</p>
          </div> */}
          <div>
            <b>
              <p>Based in</p>
            </b>
            <p>Jaipur, Rajasthan</p>
          </div>
          <div>
            <b>
              <p>Socials</p>
            </b>
            <SocialIcons />
          </div>
        </div>

        <div className={styles.contact_right}>
          <b>
            <p>Drop a question for us or just say Hello!</p>
          </b>
          <form action="" className={styles.contact_form}>
            <div>
              {/* <label htmlFor="name">Full name</label> */}
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Full name"
              />
              <br />
            </div>

            <div>
              {/* <label htmlFor="email">E-mail id</label> */}
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail id"
              />
              <br />
            </div>

            <div>
              {/* <label htmlFor="subject">Subject</label> */}
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject"
              />
              <br />
            </div>

            <div>
              {/* <label htmlFor="message">Message</label> */}
              <input
                type="text"
                name="message"
                id="message"
                placeholder="Message"
              />
              <br />
            </div>
          </form>
          <div className={styles.submit_btn_container}>
            <button className={styles.submit_btn}>Submit</button>
          </div>
        </div>
      </div>
    </LinkedPageTemplate>
  );
};

export default ContactUsPage;
