import React, { useEffect } from "react";

import LinkedPageTemplate from "../../components/LinkedPageTemplate/LinkedPageTemplate";
import styles from "./styles.module.css";
import SmartTripCardLeft from "../../components/SmartTripCardLeft/SmartTripCardLeft";
import SmartTripCardRight from "../../components/SmartTripCardRight/SmartTripCardRight";

const TroubleshootersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LinkedPageTemplate
      heading="TROUBLESHOOTERS"
      img_src="/troubleshooters_banner.png"
      btn_img="/menu_dots_green.png"
      logo_img="/logo_green.png">
      <p>
        The feature of troubleshooters is an innovative solution that provides
        local mechanic services, on-the-go maintenance and assistance for EVs,
        ensuring a seamless experience for EV owners. Here's a little sketch on
        how the troubleshooters work -
      </p>

      <SmartTripCardLeft img={"/local_mechanic.png"}
        title={"Local mechanics"}
        description={`ElectrichHiive is collaborating with a network of local mechanics
            and service centres across various routes and cities. These
            mechanics are trained to professionally handle Evs and equipped with
            the necessary tools and spare parts to provide quick and efficient
            maintenance and repairs.`} />
      <SmartTripCardRight img={"/onroad_assistance.png"}
        title={"On-the-road assistance"}
        description={`Whenever you encounter any issue with your Ev whilst you're on the
            road, you can request assistance through ElectricHiive
            Troubleshooter and someone will be there to assist you in no time.`} />

      <SmartTripCardLeft img={"/book_service.png"}
        title={"Book services and maintenance"}
        description={`In addition to on-the-road assistance, Troubleshooters facilitate
            the booking of services and maintenance appointments for the EV
            user. Users can schedule routine tasks such as battery checks,
            software updates and general inspections through the ElectricHiive's
            Troubleshooter feature.`} />

      <SmartTripCardRight img={"/quality_assurance.png"}
        title={"Quality Assurance and feedbacks"}
        description={`ElectricHiive maintains quality standards by providing the
            Troubleshooters (local mechanics) with advanced tools and equipment
            and training them to ensure proficiency in handling the EVs and
            delivering excellent customer service.`} />


      {/* <div className={styles.section1}>
        <p className={styles.heading_mobile_v}>
          <b>Local mechanics</b>
        </p>
        <img
          src="/local_mechanic.png"
          alt=""
          className={styles.ts_illustration}
        />
        <div className={styles.txt_wrapper}>
          <p className={styles.txt_heading}>
            <b>Local mechanics</b>
          </p>
          <p className={styles.txt_para}>
            ElectrichHiive is collaborating with a network of local mechanics
            and service centres across various routes and cities. These
            mechanics are trained to professionally handle Evs and equipped with
            the necessary tools and spare parts to provide quick and efficient
            maintenance and repairs.
          </p>
        </div>
      </div>

      <div className={styles.section2}>
        <p className={styles.heading_mobile_v}>
          <b>On-the-road assistance</b>
        </p>
        <img
          src="/onroad_assistance.png"
          alt=""
          className={styles.ts_illustration}
        />
        <div className={styles.txt_wrapper}>
          <p className={styles.txt_heading}>
            <b>On-the-road assistance</b>
          </p>
          <p className={styles.txt_para}>
            Whenever you encounter any issue with your Ev whilst you're on the
            road, you can request assistance through ElectricHiive
            Troubleshooter and someone will be there to assist you in no time.
          </p>
        </div>
      </div>

      <div className={styles.section3}>
        <p className={styles.heading_mobile_v}>
          <b>Book services and maintenance</b>
        </p>
        <img
          src="/book_service.png"
          alt=""
          className={styles.ts_illustration}
        />
        <div className={styles.txt_wrapper}>
          <p className={styles.txt_heading}>
            <b>Book services and maintenance</b>
          </p>
          <p className={styles.txt_para}>
            In addition to on-the-road assistance, Troubleshooters facilitate
            the booking of services and maintenance appointments for the EV
            user. Users can schedule routine tasks such as battery checks,
            software updates and general inspections through the ElectricHiive's
            Troubleshooter feature.
          </p>
        </div>
      </div>

      <div className={styles.section4}>
        <p className={styles.heading_mobile_v}>
          <b>Quality Assurance and feedbacks</b>
        </p>
        <img
          src="/quality_assurance.png"
          alt=""
          className={styles.ts_illustration}
        />
        <div className={styles.txt_wrapper}>
          <p className={styles.txt_heading}>
            <b>Quality Assurance and feedbacks</b>
          </p>
          <p className={styles.txt_para}>
            ElectricHiive maintains quality standards by providing the
            Troubleshooters (local mechanics) with advanced tools and equipment
            and training them to ensure proficiency in handling the EVs and
            delivering excellent customer service.
          </p>
        </div>
      </div> */}
    </LinkedPageTemplate>
  );
};

export default TroubleshootersPage;
