import React, { useEffect } from "react";

import LinkedPageTemplate from "../../components/LinkedPageTemplate/LinkedPageTemplate";
import styles from "./styles.module.css";
import SmartTripCardLeft from "../../components/SmartTripCardLeft/SmartTripCardLeft";
import SmartTripCardRight from "../../components/SmartTripCardRight/SmartTripCardRight";
const AllinOnePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LinkedPageTemplate
      heading="ALL IN ONE CHARGING STATION"
      img_src="/allinone_banner.png"
      btn_img="/menu_dots_green.png"
      logo_img="/logo_green.png">
      <div className={styles.allinone_content}>

        <SmartTripCardLeft img={"/allinone_EHiive.png"}
        title={"All stop solution"}
        description={`The application provides the services and all stop solution, one
              of which is all in one charging stations. A one of the primary
              features of the app where the electric vehicle user can access
              charging stations near their location, without the inconvenience
              of the multiple applications in their mobile phone.`} />
<SmartTripCardRight img={"/Bike.png"}
        title={"Smart Charging Solutions"}
        description={ <>
          Electrichiive have collaborated with numerous charging station brands to provide a seamless experience of charging, and promoting zero-emission vehicles.
          <br />
          All in one charging station is a feedback-driven, user-friendly UI, where you can find charging stations for EVs, be it two-wheeler or <br /> four-wheeler — this feature has got your back.
        </>} />

<SmartTripCardLeft img={"/car3.png"}
        title={"Universal Solutions for Your EV"}
        description={`Our feature is non brand specific, we collaborate with both local
          and Giants of EV, we simply are the aggregator. Just select your
          EV from your list in the app and we will show you all compatible
          charging stations, with their status of charging rating and if
          they are active or not. And if you find non-marked station or
          service in your area geotag it in the app and get your reward.
          See! get charged and get reward, aren't we the all stop solution.`} />



          {/* <div className={styles.section1}>
            <p className={styles.heading_mobile_v}>
              <b>All stop solution</b>
            </p>
            <img
              src="/allinone_EHiive.png"
              alt=""
              className={styles.allinone_EHiive}
            />
            <div className={styles.txt_wrapper}>
              <p className={styles.allinone_heading}>
                <b>All stop solution</b>
              </p>
              <p className={styles.allinone_para}>
                The application provides the services and all stop solution, one
                of which is all in one charging stations. A one of the primary
                features of the app where the electric vehicle user can access
                charging stations near their location, without the inconvenience
                of the multiple applications in their mobile phone.{" "}
              </p>
              <br />
            </div>
          </div> */}

        {/* <div className={styles.section2}>
          <p className={styles.heading_mobile_v}>
            <b>Smart Charging Solutions</b>
          </p>
          <img src="/Bike.png" alt="" className={styles.allinone_EHiive} />
          <div className={styles.txt_wrapper}>
            <p className={styles.allinone_heading}>
              <b>Smart Charging Solutions</b>
            </p>
            <p className={styles.allinone_para}>
              Electrichiive have collaborated with numerous charging stations
              brands to provide a seamless experience of charging, and promoting
              zero emission vehicles.
              <br />
              All in one charging station, is a feedback driven user friendly
              UI, where you can find charging stations for EV, be it two wheeler
              or four wheeler, this feature got your back.
            </p>
            <br />
          </div>
        </div>

        <div className={styles.section3}>
          <p className={styles.heading_mobile_v}>
            <b>Universal Solutions for Your EV</b>
          </p>
          <img src="/car3.png" alt="" className={styles.allinone_EHiive} />
          <div className={styles.txt_wrapper}>
            <p className={styles.allinone_heading}>
              <b>Universal Solutions for Your EV</b>
            </p>
            <p className={styles.allinone_para}>
              Our feature is non brand specific, we collaborate with both local
              and Giants of EV, we simply are the aggregator. Just select your
              EV from your list in the app and we will show you all compatible
              charging stations, with their status of charging rating and if
              they are active or not. And if you find non-marked station or
              service in your area geotag it in the app and get your reward.
              See! get charged and get reward, aren't we the all stop solution.
            </p>
          </div>
        </div> */}
      </div>
    </LinkedPageTemplate>
  );
};

export default AllinOnePage;
