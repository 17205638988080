import React, { useEffect } from "react";

import LinkedPageTemplate from "../../components/LinkedPageTemplate/LinkedPageTemplate";

import styles from "./styles.module.css";

import { PiTargetBold } from "react-icons/pi";
import { GiStairsGoal } from "react-icons/gi";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LinkedPageTemplate
      heading="ABOUT US"
      img_src="/aboutus_banner.png"
      btn_img="/menu_dots_white.png">
      <p>
        Two friends had an idea, an idea that would help create a hassle free
        world of EVs. ElectricHiive is a platform to assist you with all
        charging stations across all companies and platforms near you. Plan your
        trip with our planner and get the optimal route according to your
        starting and destination point depending on the current charge % of EV,
        with assistance for charging and all stations in route your trip.
      </p>
      <br />

      <div className={styles.vision_mission_container}>
        <div>
          <p className={styles.vm_heading}>
            <span>
              <PiTargetBold />
            </span>
            <b>OUR VISION</b>
          </p>
          <p>
            With the vision of sweeping widespread acclimatisation of the
            application across the country, ElectricHiive inspires to promote
            consumption of carbon emission-free transportation for a better
            future.{" "}
          </p>
        </div>

        <div>
          <p className={styles.vm_heading}>
            <span>
              <GiStairsGoal />
            </span>{" "}
            <b>OUR MISSION</b>
          </p>
          <p>
            Mission is to generate a user experience by simplifying the charging
            process with an end-to-end unified network all at a single tap and
            with additional services like Troubleshooters (local mechanics), a
            Smart trip planner, and roadside assistance.
          </p>
        </div>
      </div>

      <p>
        <b>OUR TEAM</b>
      </p>
      <div className={styles.team_imgs_container}>
        <div>
          <img src="/rajat.png" alt="" className={styles.team_member_img} />
          <div className={styles.member_info}>
            <p className={styles.member_name}>Rajat Singh</p>
            <p className={styles.member_designation}>Co-Founder</p>
          </div>
        </div>
        <div>
          <img src="/aryan.png" alt="" className={styles.team_member_img} />
          <div className={styles.member_info}>
            <p className={styles.member_name}>Aryan Tripathi</p>
            <p className={styles.member_designation}>Co-Founder</p>
          </div>
        </div>
        {/* <div>
          <img src="/ekamruth.png" alt="" className={styles.team_member_img} />
          <div className={styles.member_info}>
            <p className={styles.member_name}>Ekamruth Gangaraju</p>
            <p className={styles.member_designation}>Tech Lead</p>
          </div>
        </div>
        <div>
          <img src="/aruni.png" alt="" className={styles.team_member_img} />
          <div className={styles.member_info}>
            <p className={styles.member_name}>Aruni Bajpai</p>
            <p className={styles.member_designation}>Lead Graphic Designer</p>
          </div>
        </div> */}
      </div>
    </LinkedPageTemplate>
  );
};

export default AboutPage;
