import React from 'react'

import styles from './styles.module.css'

import { useState, useEffect } from 'react';
import { motion, useScroll, useTransform, useMotionValueEvent } from 'framer-motion'

// import EllipsisBtn from './components/EllipsisBtn/EllipsisBtn';

// import {HomeCard, AboutCard, ServicesCard, ContactUsCard, FaqCard, NavModal} from '../../components/index'

import HomeCard from '../../components/Cards/HomeCard/HomeCard'
import AboutCard from '../../components/Cards/AboutCard/AboutCard'
import ServicesCard from '../../components/Cards/ServicesCard/ServicesCard'
import ContactUsCard from '../../components/Cards/ContactUsCard/ContactUsCard'
import FaqCard from '../../components/Cards/FaqCard/FaqCard'
import NavModal from '../../components/NavModal/NavModal'

import Footer from '../../components/Footer/Footer'


export default function HomePage() {

  const [isInSection, setIsInSection] = useState(false)
  const [isCarChanged, setIsCarChanged] = useState(false)
  const [isCardocked, setIsCarDocked] = useState(false)
  const [isInSection2, setIsInSection2] = useState(false)

  const [isDock2Visible, setIsDock2Visible] = useState(false)

  const [isSection3Visible, setIsSection3Visible] = useState(false)
  const [isSection4Visible, setIsSection4Visible] = useState(false)

  const { scrollY } = useScroll();
  const carTranslate = useTransform(scrollY, latest => (latest - latest * 0.2) * 0.23)
  const carTransform = useTransform(scrollY, latest => (latest - latest * 0.9) * -0.35)
  // const car3Translate = useTransform(scrollY, latest => (latest - latest * 0.9) * 0.1)

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false)
  }

  useMotionValueEvent(carTransform, "change", (latest) => {
    // console.log(latest)
    // console.log(latest <= -34)
    latest <= -31.75 ? setIsCarChanged(true) : setIsCarChanged(false)
    setTimeout(() => {
      latest <= -31.75 ? setIsCarDocked(true) : setIsCarDocked(false)
    }, 100);
  })

  // const [inSection, setInSection] = useState(false);
  // const carImageRef = useRef(null);

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     setInSection(entries[0].isIntersecting);
  //   });

  //   if (carImageRef.current) {
  //     observer.observe(carImageRef.current.parentNode); // Observe parent section
  //   }

  //   return () => observer.disconnect(); // Cleanup on unmount
  // }, [carImageRef.current]);




  // let sectionRect = null
  // if (triggerSection != null) {
  //   sectionRect = triggerSection.getBoundingClientRect();
  // }
  // console.log(triggerSection)

  // window.addEventListener('scroll', () => {
  //   let carRect = null
  //   if (carImage != null) {
  //     carRect = carImage.getBoundingClientRect(); // Get car image's rectangle
  //     console.log(carRect)
  //     const isIntersecting = carRect.bottom > sectionRect.top;
  //     carImage.dataset.inSection = isIntersecting ? "true" : "false";
  //   }    // Check if car image bottom is lower than section top

  // });
  // console.log(triggerSection)
  // const options = {
  //   root: triggerSection,
  //   threshold: 0.1,
  // }

  useEffect(() => {
    const scooterImg = document.getElementById('scooter');
    const triggerSection = document.getElementById('charging_dock1');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        scooterImg.dataset.inSection = entry.isIntersecting ? "true" : "false";
        scooterImg.dataset.inSection === "true" ? setIsInSection(true) : setIsInSection(false);
      });
    }, {
      threshold: 1
    });

    observer.observe(triggerSection);

  }, [])


  useEffect(() => {

    const carImage = document.getElementById('orange_car');
    const triggerSection2 = document.getElementById('about')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (carImage) {
          carImage.dataset.inSection = entry.isIntersecting ? "true" : "false";
          carImage.dataset.inSection === "true" ? setIsInSection2(true) : setIsInSection2(false);
        }
      });
    }, {
      threshold: 0.2
    });
    observer.observe(triggerSection2);
    // else if(!isInSection){
    //   setIsInSection2(false)
    // }
  }, [])


  useEffect(() => {

    console.log('Is in section2 :', isInSection2)
    const car2Img = document.getElementById('orange_car2');
    const Dock2 = document.getElementById('about');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (car2Img) {
          car2Img.dataset.inSection = entry.isIntersecting ? "true" : "false";
          car2Img.dataset.inSection === "true" ? setIsDock2Visible(true) : setIsDock2Visible(false);
        }
      });
    }, {
      threshold: 0.7
    });

    observer.observe(Dock2);

  }, [isInSection2])


  useEffect(() => {
    console.log("last:", isDock2Visible)
    if (isDock2Visible) {
      const carImage = document.getElementById('orange_car3');
      const triggerSection2 = document.getElementById('services')

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (carImage) {
            carImage.dataset.inSection = entry.isIntersecting ? "true" : "false";
            console.log('section3:', carImage.dataset.inSection)
            carImage.dataset.inSection === "true" ? setIsSection3Visible(true) : setIsSection3Visible(false);
          }
        });
      }, {
        rootMargin: '50px'
      });
      console.log(carImage)
      observer.observe(triggerSection2);
    }

  }, [isDock2Visible])


  useEffect(() => {
    // console.log("last:", isDock2Visible)
    
    const carImage = document.getElementById('orange_car4');
    const triggerSection2 = document.getElementById('faq')
    console.log(triggerSection2, "trigger")
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (carImage) {
          carImage.dataset.inSection = entry.isIntersecting ? "true" : "false";
          console.log('section3:', carImage.dataset.inSection)
          carImage.dataset.inSection === "true" ? setIsSection4Visible(true) : setIsSection4Visible(false);
        }
      });
    }, {
      threshold: 0
    });
    // console.log(carImage)
    observer.observe(triggerSection2);

  }, [])


  // useEffect(() => {
  //   const carImage = document.getElementById('orange_car');
  //   const sectionRect = document.getElementById('home').getBoundingClientRect()
  //   const carRect = carImage.getBoundingClientRect(); // Get car image's rectangle
  //   console.log(carRect, sectionRect)
  //   console.log(scrollY.current)
  //   console.log(Carref)
  //   if (scrollY) {
  //     const isIntersecting = carRect.bottom > sectionRect.top;
  //     carImage.dataset.inSection = isIntersecting ? "true" : "false";
  //   }

  //   // Check if car image bottom is lower than section top
  // }, [scrollY])

  // const carRotate = useTransform(scrollY, latest => latest * 3.5); 

  // const Carref = useRef(null);

  // useEffect(() => {
  //   const element = Carref.current;
  //   if (element) {
  //     element.style.transform = `translateX(${carTranslate.get()}px) translateY(${carTranslate.get()}px) rotate(${carRotate.get()}deg)`;
  //   }
  // }, [carTranslate, carRotate]);

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24" id='main'>
      {showModal ? <NavModal handleModalClose={handleModalClose} /> : null}
      <img className={styles.bg} src='/BG2.png' alt="bg_img" />
      <img className={styles.bg_mobile} src='/bg_mobile.png' alt="bg_img" />
      <button onClick={() => { setShowModal(true) }} className={styles.menu_btn}><img src='/menu_dots_white.png' alt="" className={styles.open_modal_dots} /></button>
      <img src="/logo_white.png" alt="logo_white" className={styles.logo_white} />

      {!isCardocked ? !isCarChanged ?
        isInSection ? <motion.img drag src="/Scooter.png" id="scooter" className={styles.scooter_insection} alt="scooter2"

          style={{ translateX: carTranslate, rotate: carTransform }} /> : null
        : null
        : null}

      {isCarChanged ?
        isInSection ? <motion.img drag src="/Scooter.png" id="scooter" className={styles.scooter_insection_docking} alt="scooter3"
          initial={{
            rotate: "-37deg"
          }}
          animate={{ rotate: "0deg", translateY: '100px' }}
          transition={{ duration: 0.5 }}
        /> : null
        : null
      }

      {!isCardocked ? !isInSection ? <img src="/Scooter.png" id="scooter" alt="scooter1" className={styles.scooter} /> : null
        : null}

      {/* {isCardocked ? <img src="/Scooter.png"  alt="scooter4" className={styles.scooter_docked} /> : null} */}


      {/* <div className={styles.welcome_txt_container}>
        <p className={styles.welcome_txt}><span>WELCOME!!</span></p >
        <img src="/scroll_to_roll_white.png" alt="" className={styles.sroll_to_roll} />
      </div> */}
      <img src="/scroll_to_roll_white.png" alt="" className={styles.sroll_to_roll} />

      {/* Car1 */}
      {!isInSection2 ? <img src="/orange_car.png" alt="orange_car1" id='orange_car' className={styles.orange_car} /> : null}

      {/* Car2 */}
      {!isSection3Visible ? !isDock2Visible ? isInSection2 ? <motion.img src="/orange_car.png" id='orange_car2' alt="orange_car2" className={styles.orange_car2}
        initial={{
          rotate: "0deg"
        }}
        animate={{ rotate: ['0deg', '10deg', '20deg', '10deg', '0deg'], translateX: '-100px', translateY: '80px' }}
        transition={{ ease: "easeInOut", duration: 0.5 }}

      /> : null
        : null
        : null}

      {/* Car3 */}
      {isDock2Visible ? !isSection3Visible ?
        <motion.img
          src="/orange_car.png" id='orange_car3' alt="orange_car3" className={styles.orange_car3}
          initial={{
            rotate: "0deg"
          }}
          animate={{ rotate: ['-30deg', '-10deg', '0deg'], translateX: '40px', translateY: '300px' }}
          transition={{ ease: "easeInOut", duration: 0.8 }}
        />
        : null
        : null}

      {/* Car4 */}
      {isSection3Visible ? <motion.img
        src="/orange_car.png" id='orange_car4' alt="orange_car4" className={styles.orange_car4}
        initial={{
          rotate: "0deg"
        }}
        animate={{ rotate: ['0deg', '10deg', '20deg', '10deg', '0deg'], translateX: '-80px', translateY: '160px' }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      />
        : null}

      {/* Car5 */}
      {isSection4Visible ? <motion.img
        src="/orange_car.png" id='orange_car5' alt="orange_car5" className={styles.orange_car5}
        initial={{
          rotate: "0deg"
        }}
        animate={{ rotate: ['-30deg', '-10deg', '0deg'], translateX: '40px', translateY: '300px' }}
        transition={{ ease: "easeInOut", duration: 0.8 }}
      />
        : null}


      <motion.img src="/blue_car.png" alt="blue_car" className={styles.blue_car}  />
    


      <section className={styles.section1} id='home'>
        <div className={styles.section1_container}>
          <img src="/charging_dock.png" alt="charging_dock" id='charging_dock1' className={styles.charging_dock1} />
          {isInSection ? <HomeCard /> : null}
        </div>
      </section>


      <section className={styles.section2} id='about'>
        <div className={styles.section2_container}>
          <img src="/charging_dock.png" alt="charging_dock" id='charging_dock2' className={styles.charging_dock2} />
          <AboutCard />
        </div>
      </section>


      <section className={styles.section3} id='services'>
        <div className={styles.section3_container}>
          {/* <img src="/troubleshooters.png" alt="troubleshooters" className={styles.troubleshooters} /> */}
          <ServicesCard />
        </div>
      </section>


      <section className={styles.section4} id='faq'>
        <div className={styles.section4_container}>
          <img src="/charging_dock.png" alt="charging_dock" id='charging_dock3' className={styles.charging_dock3} />
          <FaqCard />
        </div>
      </section>


      <section className={styles.section5} id='contact'>
        <div className={styles.section5_container}>
          {/* <img src="/charging_dock.png" alt="charging_dock" className={styles.charging_dock4} /> */}
          <ContactUsCard />
        </div>
      </section>
      
        <Footer />
    </main>
  );
}