import React from 'react'

import styles from './styles.module.css'
import LinkedPageTemplate from '../../components/LinkedPageTemplate/LinkedPageTemplate'

const PrivacyPolicyPage = () => {
  return (
    <LinkedPageTemplate
      heading='PRIVACY POLICY'
      img_src='/privacypolicy_banner.png'
      btn_img='/menu_dots_white.png'
      logo_img='/logo_white.png'
    >
      This is privacypolicy page!
    </LinkedPageTemplate>
  )
}

export default PrivacyPolicyPage
