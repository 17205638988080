import React from 'react'

import styles from './styles.module.css'

import LinkedPageTemplate from '../../components/LinkedPageTemplate/LinkedPageTemplate'

const CookiesPolicyPage = () => {
  return (
    <LinkedPageTemplate
      heading='COOKIE POLICY'
      img_src='/cookiepolicy_banner.png'
      btn_img='/menu_dots_green.png'
      logo_img='/logo_green.png'
    >
      This is cookies policy page!
    </LinkedPageTemplate>
  )
}

export default CookiesPolicyPage
