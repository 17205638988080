import React from 'react'
import styles from './styles.module.css'

import LinkedPageTemplate from '../../components/LinkedPageTemplate/LinkedPageTemplate'

const TandCPage = () => {
  return (
    <LinkedPageTemplate
      heading='TERMS AND CONDITIONS'
      img_src='/tandc_banner.png'
      btn_img='/menu_dots_white.png'
      logo_img='/logo_white.png'
    >
      This is tandc page!
    </LinkedPageTemplate>
  )
}

export default TandCPage
