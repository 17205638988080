import React from 'react'

import styles from './styles.module.css'

import { MdClose } from "react-icons/md";
import { Link } from 'react-router-dom';


const NavModal = (props) => {
  return (
    <div className={styles.nav_modal}>
      <button className={styles.nav_close_btn} onClick={props.handleModalClose}><MdClose /></button>
      <div className={styles.nav_modal_container}>
        <Link to='/' onClick={props.handleModalClose}>HOME</Link>
        <Link to='/aboutUS' onClick={props.handleModalClose}>ABOUT US</Link>
        <Link to='/contactUS' onClick={props.handleModalClose}>CONTACT US</Link>
        <Link to='/smarttrip' onClick={props.handleModalClose}>SMART TRIPS</Link>
        <Link to='/allinone' onClick={props.handleModalClose}>ALL IN ONE CHARGING STATION</Link>
        <Link to='/troubleshooters' onClick={props.handleModalClose}>TROUBLESHOOTERS</Link>
      </div>
    </div>
  )
}

export default NavModal
